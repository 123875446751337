.InputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  input {
    background: var(--dark-200);
    border-radius: var(--radius);
    border: 1px solid var(--dark-500);
    color: white;
    margin: 0;
    padding: 5px 15px;
    width: 100%;

    &:focus {
      outline: none;
      border: 1px solid var(--dark-600);
    }
  }
}
