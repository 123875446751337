.GlowBorder {
  position: relative;
  padding: 1px;
  background: var(--border-color);
  border-radius: var(--border-radius);
  isolation: isolate;
  overflow: hidden;
  width: max-content;
  height: max-content;

  .border-glanse {
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    filter: blur(50px);
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}

.glowBorderContent {
  border-radius: calc(var(--radius) - 1px);
  height: max-content;
  width: max-content;
}
