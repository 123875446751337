@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --dark-100: #181a1a;
  --dark-200: hsl(192, 7%, 14%);
  --dark-300: hsla(0, 0%, 13%, 0.386);
  --dark-400: #3d3d3d;
  --dark-500: #3d3d3d;
  --dark-600: #7e7e7e;
  --dark-700: #777777;
  --dark-800: #959595;

  --placeholder: #ffffff80;

  --light-100: #ffffff;

  --brand-100: #8c6bd2;
  --brand-focus: rgba(174, 137, 255, 0.32);

  --radius: 5px;

  --desktop-width: 70rem;
  --md: 1120px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;

  &::selection {
    color: var(--dark-100);
    background: var(--light-100);
  }
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--dark-100);
  color: var(--light-100);
  width: 100vw;
  overflow-x: hidden;
}

button {
  background: none;
  color: var(--light-100);
  border: none;
}

h1 {
  margin-bottom: 15px;

  &.HeroHeader {
    font-size: 4rem;
  }
}

h3 {
  font-weight: 300;

  &.HeroSubheader {
    font-size: 1.2rem;
    font-weight: 200;
  }
}

a {
  color: white;
  text-decoration: none;
  color: var(--placeholder);
  transition: transform 0.15s ease, color 0.15s ease;
  position: relative;

  &:hover {
    color: white;
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }

  &.unstyled {
    color: white;
    transition: unset;

    &:hover {
      color: white;
      transform: unset;
    }

    &:active {
      color: white;
      transform: unset;
    }
  }
}

.templates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.Cover {
  width: 595px;
  height: 849px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  &.saving {
    border-radius: 0;
    transform: translate(1000%, -50%) scale(4);
    box-shadow: none;
  }
}

.list {
  h1,
  h2,
  h3,
  h4,
  h5 {
    border-bottom: 1px solid var(--dark-400);
    padding-bottom: 10px;
  }

  .InputContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--dark-400);
    padding-bottom: 15px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    label {
      width: min-width;
    }

    input {
      width: 20rem;
    }
  }
}
