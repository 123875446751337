.Page {
  display: flex;
  flex-direction: row;

  .preview,
  .options {
    flex: 1;
    height: 100svh;
    padding: 30px;
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    background: var(--dark-100);
    border-right: 1px solid black;

    .list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .optionGroup {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .preview {
    background: var(--dark-200);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .save {
      position: absolute;
      bottom: 5rem;
    }
  }
}
