.Cover.Template1 {
  padding: 55px;
  background: var(--background);

  h3 {
    color: var(--accent);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  h1 {
    color: var(--text);
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  h2 {
    color: var(--text);
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    opacity: 0.5;
  }

  .CoverHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    .Info {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .CoverImage {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;

      .ImageText {
        color: var(--accent);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .CoverBody {
    display: flex;
    flex-direction: column;
    gap: 95px;
    align-self: stretch;

    .CoverHero {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .People {
      display: flex;
      gap: 50px;
      justify-content: space-between;

      div {
        display: flex;
        flex-direction: column;
        gap: 10px;

        p {
          color: var(--text);
        }

        &.Mentors {
          p {
            width: max-content;
          }
        }
      }
    }
  }
}
