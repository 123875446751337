.TemplateCard {
  width: 100%;
  height: 21rem;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: var(--dark-600);
  }

  img {
    position: relative;
    left: 50%;
    bottom: -5rem;
    translate: -50%;
    transition: bottom 0.15s ease, rotate 0.15s ease;
  }

  &:hover {
    img {
      bottom: -4rem;
      rotate: 5deg;
    }
  }
}
