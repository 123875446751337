@import url("https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap");

.Template2 {
  background: var(--background);
  padding: 60px;
  color: var(--text);

  p {
    font-size: 12px;
    text-transform: uppercase;
  }

  h1 {
    font-size: 62px;
    font-family: "Judson", serif;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
  }

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }

  .Hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-self: stretch;

    .byline {
      text-align: center;
      width: 70%;
    }
  }

  .Footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
  }
}
