.Button {
  border-radius: 5px;
  border-top: 0.5px solid var(--dark-600, #7e7e7e);
  background: var(--dark-500, #5e5e5e);
  box-shadow: 0px 1px 2.4px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: max-content;

  &:active {
    border-color: var(--dark-800);
    background: var(--dark-700);
  }
}
