.Checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  user-select: none;

  .checkboxElement {
    background: var(--brand-100);
    width: 15px;
    height: 15px;
    border-radius: 3px;
    border: 1px solid var(--dark-400);
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 100%;
      height: 100%;
      background: var(--dark-300);
      border-radius: 2px;
      transition: all 0.25s ease;
    }

    &.checked {
      &::after {
        width: 0px;
        height: 0px;
        opacity: 0;
      }
    }
  }

  &:hover {
    .checkboxElement {
      border-color: var(--dark-500);

      &::after {
        background: var(--dark-400);
      }
    }
  }
}
